import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ChainLink from '../../../assets/images/chain-link-gates.jpg'

class ChainLinkGate extends React.Component {
	render() {
		const siteTitle = 'Chain Link Gates - Arlington, TX - Dallas-Fort Worth, TX'
		const siteDescription =
			'We provide custom chain link fences and gates for homes and businesses in Arlington, Dallas, and Fort Worth. Call us today for a repair or installation!'

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">
							Chain Link Gates in Arlington, TX
						</h1>
						<hr />
					</div>
				</div>

				<div className="container d-padding-t d-padding-b access-sys">
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-12">
							<p>
								When you need a cost-effective security solution for your
								property, chain link gates are an excellent choice. Whether you
								need residential or commercial chain link gates in Dallas or
								Fort Worth, TX, we can help! Call J & J Gates Service and Design
								at for more information!
							</p>
							<h4>Benefits of Chain Link Gate Systems</h4>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-8">
							<p>
								Affordable and low maintenance, chain link fences and gates have
								been in use for many decades.{' '}
								<strong>
									Available in any height and width you need, these systems work
									well for any residential or commercial setting
								</strong>
								. Do you want a simple swing gate, or a large, automatic gate
								system? Our experienced installers can help you find the right
								chain link gate system for you! We work with all types of gate
								openers and access systems to provide you with the best solution
								possible.
							</p>
							<p>
								For professional chain link gate installation in Dallas and Fort
								Worth, TX, look no further than J & J Gates Service and Design.
								With over a decade of experience in the business and in the DFW
								area, we are prepared to help you find the gate you need.
								Contact us today if you have questions about our automatic or
								commercial chain link gate services and more!
							</p>
						</div>
						<div className="col-md-4 mt-4">
							<LazyLoadImage
								className="img-fluid"
								src={ChainLink}
								alt="Chain Link Gate"
							/>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<p>
								<strong>
									Chain link gates and fences are almost endlessly customizable
								</strong>
								. They can be designed for high-security areas as well as a
								simple gate into your backyard. With long-lasting durability and
								their low cost, chain link gates are a popular choice for all
								types of properties. From schools to large commercial
								facilities, we can handle any chain link gate system you need.
								Give J & J Gates Service and Design a call today to learn more
								about our available styles and types.
							</p>
							<p>
								In addition to chain link gate installation in Dallas and Fort
								Worth, TX, we also provide chain link gate{' '}
								<Link to="/gates/services/gate-repair">repair</Link>. If you
								have an old gate or damaged hinge, call us at to learn more
								about our chain link gate repair in DFW.{' '}
								<strong>
									<em>
										We guarantee our work and do everything we can to meet your
										complete satisfaction
									</em>
								</strong>
								.
							</p>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default ChainLinkGate

export const ChainLinkGatePageQuery = graphql`
	query ChainLinkGatePageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
